import React, { useCallback, useEffect, useState } from "react";

import "./index.css";

import {
  Typography,
  Button,
  Row,
  Col,
  Modal,
  Space,
  Image,
  Dropdown,
  Menu,
  Tooltip,
  Alert,
  notification,
} from "antd";
import { useGlobal } from "../../../store/index";
import { Colors } from "../../../theme";
import ProductPreviewModal from "./ProductPreviewModal";
import { useParams, useHistory, useLocation } from "react-router-dom";
import ShareCatalogue from "./ShareCatalogue";
import ImagePlacholder from "../../../assets/images/image-placeholder.png";
import EducationModal from "./EducationModal";
import CatalogueDetails from "./catalogueDetails";
import API_KEY from "../../../api";
import Axios from "axios";
import Loading from "components/LoadingModal";
import StickyBox from "react-sticky-box";
import ShortlistSvg from "./Shortlist.svg";
import CatalogueSvg from "./Catalogue.svg";
import wishlist from "../../../assets/images/wishlist.svg";
import DualCol from "../../../assets/images/twoCol.svg";
import SingleCol from "../../../assets/images/single-col.svg";
import CloseIcon from "../../../assets/images/closeTransparent.svg";
import WishlistedBlip from "../../../assets/images/WishlistedBlip.svg";

import ShortlistOutline from "../../../assets/images/ShortlistOutline.svg";
import ShortlistBlip from "../../../assets/images/ShortlistBlip.svg";

import { MdCloudDownload } from "react-icons/md";
import { sendAnalyticsEvent } from "utils/sendAnalyticsEvent";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ProductActions from "redux/actions/productActions";
import HeaderImageComponent from "../Home/BannerImage";

import axios from "axios";
import moment from "moment";
import ExpireLink from "../Catalogue/ExpireLink";
import EmailInputModal from "../Catalogue/EmailModal";
import MediaQuery from "react-responsive";
import { WarningFilled } from "@ant-design/icons";
import { setItem, getItem, removeItem } from "utils/localStorage";

import CatalogueDrawer from "./catalogueDrawer";
import OtpVerficationModal from "./OtpVerficationModal";
import NoAccessModal from "./NoAccessModal";
import {
  CATALOGUE_LINK_SETTINGS_KEY,
  CATALOGUE_SETTINGS_KEY,
  DEFAULT_WATERMARK_SETTINGS,
  PRODUCT_CARD_SETTINGS_KEY,
} from "constants/index";
import { getManufacturerSettings } from "api/quotation";
const { Text } = Typography;

function Catalogue({
  checkedProducts,
  actions,
  setIsAccessGranted,
  isAccessGranted,
  visitorInfo,
  fetchingUserData,
  setIsUserAllowed,
  sessionId,
  isUserAllowed,
}) {
  const [collectionDetails, setCollectionDetails] = useState(null);
  const [modalData, setModalData] = useState([]);
  const [nextProduct, setNextProduct] = useState(null);
  const [prevProduct, setPrevProduct] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [currentProductIndex, setCurrentProductIndex] = useState(null);
  const [globalState, setGlobalState] = useGlobal();
  const [showShareModal, setShowShareModal] = useState(false);
  const [allProductHits, setAllHits] = useState([]);
  const params = useParams();
  const searchString = window.location.search;
  const urlParams = new URLSearchParams(searchString);
  const collection_link = params?.id || window?.name;
  const [education, setEducation] = useState(false);
  const history = useHistory();
  const [spinning, setspinning] = useState(false);
  const [filetype, setFiletype] = useState(null);
  const [collectionStatus, setCollectionStatus] = useState(null);
  const [imgLoaded, setImgLoaded] = useState(false);
  const [productCount, setProductCount] = useState(0);
  const [imageQuality, setImageQuality] = useState("");
  const [loader, setLoader] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [mobileCols, setMobileCols] = useState(
    parseInt(
      localStorage.getItem("mobileCols")
        ? localStorage.getItem("mobileCols")
        : 2
    )
  );
  const [maxAllowedProducts, setMaxAllowedProducts] = useState(400);

  const [isOtpModal, setOtpModal] = useState(false);
  const [otpEmail, setOtpEmail] = useState("");
  const [otpMobile, setOtpMobile] = useState("");
  const [isNoAccessModal, setIsNoAccessModal] = useState(false);
  const [countryCode, setCountryCode] = useState("");

  // Hits to store data from elastic API -- Suyash
  const [hits, setHits] = useState([]);
  const [facets, setFacets] = useState({});
  const [page, setPage] = useState(1);
  const [nbPages, setNbPage] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  // const [quickFilters, setQuickFilters] = useState({});
  const [showBannerOnTop, setShowBannerOnTop] = useState(true);
  const [id, setId] = useState(0);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const deviceType = globalState.deviceType;

  console.log("visitorInfo", visitorInfo);

  const { _id } = useParams();

  const [isPreview, setIsPreview] = useState(false);

  const [sellingPriceAttr, setSellingPriceAttr] = useState(null);

  const [waterMarkData, setWaterMarkData] = useState(
    DEFAULT_WATERMARK_SETTINGS
  );
  const {
    isCatalogueWatermark,
    isOtpModalWatermark,
    isEmailModalWatermark,
    isOtpEmail,
    isQuoteEmail,
  } = waterMarkData;
  const [extraAttributeToRender, setExtraAttributeToRender] = useState("");
  const { hide_catalogue_name = false } = globalState?.catalogueSettings;

  const handleChange = (value) => {
    let code = value.match(/(\d+)/);
    setCountryCode(code[0]);
  };

  useEffect(() => {
    Axios.get(
      `${process.env.REACT_APP_API_SERVICE}link_security/get_verification_data?collection_link=${params.id}`
    ).then((res) => {
      if (res.status === 200) {
        localStorage.setItem(
          "catalogueThumbnailQuality",
          res?.data?.catalogue_thumbnail_quality
        );
        setImageQuality(res?.data?.catalogue_thumbnail_quality);
      }
    });
    getManufacturerSettings({
      collection_link: params.id,
      key: CATALOGUE_LINK_SETTINGS_KEY,
    })
      .then((res) => {
        if (res?.status === 200) {
          const data = res?.data?.data;
          const {
            maximum_allowed_products_for_document_download = 400,
            powered_by = {},
          } = data || {};
          setWaterMarkData(powered_by);
          setMaxAllowedProducts(
            maximum_allowed_products_for_document_download || 400
          );
        }
      })
      .catch((err) => {
        console.error("error while fetching manufacturer features", err);
      });
  }, [params.id]);

  const IsCatalogueActive = () => {
    Axios.get(
      `${process.env.REACT_APP_API_SERVICE}link_security/get_verification_data?collection_link=${params.id}`
    ).then((res) => {
      if (
        res?.data?.status === "inactive" ||
        res?.data?.status === "archived" ||
        res?.data?.expiry
      ) {
        setIsAccessGranted(false);
        setShowExpireModal(true);
        removeItem("shortlisted_products");
        actions.setProducts([]);
        //message.error("The catalogue link has expired!!");

        history.push({
          pathname: params?.expname
            ? `/${params?.expname}/${collection_link}`
            : `/${collection_link}`,
          search: urlParams.toString(),
        });
      }
    });
  };

  const getProductCardSettings = useCallback(() => {
    if (params?.id) {
      getManufacturerSettings({
        collection_link: params?.id,
        key: PRODUCT_CARD_SETTINGS_KEY,
      })
        .then((res) => {
          if (res?.status === 200) {
            const data = res?.data?.data;
            const extraAttributeToshow = data?.sub_header_attribute;
            setExtraAttributeToRender(extraAttributeToshow);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [params?.id]);

  useEffect(() => {
    getProductCardSettings();
  }, [getProductCardSettings]);

  useEffect(() => {
    const timer = setInterval(IsCatalogueActive, 30000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const selectedProducts = JSON.parse(getItem(`shortlisted_products`));
    if (selectedProducts) {
      actions.setProducts(selectedProducts, _id);
    }

    if (!isAccessGranted) {
      actions.setProducts([]);
    }
  }, []);

  useEffect(() => {
    setLoader(true);
    if (globalState.filters === null) {
      setGlobalState.filters.onChangeFilters({});
    }
    if (urlParams.get("type") === "PREVIEW") {
      setItem("type", "PREVIEW");
      setIsPreview((state) => true);
    }
    API_KEY.URL.get(API_KEY.path.collections, {
      params: {
        "filter[collection_link][_eq]": params?.id,
        deep: { products: { _limit: -1 } },
        fields: "*",
      },
    }).then((res) => {
      setCollectionDetails(res?.data?.data?.length ? res?.data?.data[0] : "");
      setGlobalState.collectionDetails.onChangeCollectionDetails(
        res?.data?.data?.length ? res?.data?.data[0] : {}
      );
      setItem(
        "collection_details",
        res?.data?.data?.length ? JSON.stringify(res?.data?.data[0]) : ""
      );
      setInfo(res?.data?.data?.[0]);
      if (res?.data?.data?.[0]?.manufacturer_slug === strSplit[1]) {
        setIsAuthslug(true);
      }
      localStorage.setItem(
        "manufacturer_slug",
        res?.data?.data?.[0]?.manufacturer_slug
      );
      localStorage.setItem("collection_link", params?.id);

      setId(res?.data?.data?.[0].id);

      setProductCount(res?.data?.data[0]?.products?.length);
    });

    // Suyash - get products from new API
    axios
      .post(
        `${process.env.REACT_APP_API_SERVICE}items/v2/collection_products/${params.id}`,
        { page, ...globalState.filters, records_per_page: globalState.pageSize }
      )
      .then((res) => {
        if (res.status === 200) {
          setHits(res.data?.data?.hits?.length ? res.data.data.hits : []);
          setFacets(
            res.data?.data?.facets && Object.keys(res.data.data.facets).length
              ? formatFacets(res.data.data.facets)
              : {}
          );

          setProductCount(res.data?.data?.nbHits ? res.data.data.nbHits : 0);
          setNbPage(res?.data?.data?.nbPages);
          setPage(1);
          setHasMore(res?.data?.data?.nbPages > 1 ? true : false);
          if (globalState.quickFilters.length === 0) {
            createQuickFilters(formatFacets({ ...res.data.data.facets }));
          }
        }
        setLoader(false);
      });
  }, [collection_link]);

  const createQuickFilters = (_facets) => {
    // get facets with buckets
    let quickFilters = [];
    Object.keys(_facets).forEach((key) => {
      if (quickFilters.length < 5) {
        _facets[key].buckets = _facets[key].buckets.map((bucket) => {
          return { ...bucket, facet: key };
        });
        let bucketsLength = _facets[key].buckets.length;

        if (bucketsLength < 5 - quickFilters.length) {
          quickFilters = [...quickFilters, ..._facets[key].buckets];
        } else {
          quickFilters = [
            ...quickFilters,
            ..._facets[key].buckets.slice(0, 5 - quickFilters.length),
          ];
        }
      }
    });

    setGlobalState.quickFilters.onChangeQuickFilters([...quickFilters]);
  };

  const handlePrev = (currentIndex) => {
    const prevProduct = allProductHits[currentIndex - 1];
    setModalData((state) => prevProduct);
    setImgLoaded(false);
    setCurrentProductIndex(currentIndex - 1);
    sendAnalyticsEvent("open_product_modal", { product_id: prevProduct?.id });
  };

  const handleNext = (currentIndex) => {
    const nextProduct = allProductHits[currentIndex + 1];
    setModalData((state) => nextProduct);
    setCurrentProductIndex(currentIndex + 1);
    setImgLoaded(false);
    sendAnalyticsEvent("open_product_modal", { product_id: nextProduct?.id });
  };

  const handleCardClick = (item, prev, next, allHits, index) => {
    setModalData((state) => item);
    setNextProduct((state) => next);
    setPrevProduct((state) => prev);
    setAllHits(allHits);
    setCurrentProductIndex(index);
    sendAnalyticsEvent("open_product_modal", { product_id: item?.id });
    handleModal();
  };

  const getPrice = (attributes = []) => {
    const priceAttribute =
      attributes.find(
        (attr) =>
          attr.attribute_values_id.attribute_id.internal_name ===
          "base_selling_price"
      ) || null;

    if (!priceAttribute) return [];

    const { composite = {}, values } =
      priceAttribute?.attribute_values_id || {};
    const { unit = "", currency = "" } = composite;

    return [currency, values, unit];
  };

  // useEffect(() => {
  //   const currentProductData = allProducts[indexRel];
  //   const _sellingPriceAttrObj =
  //     (currentProductData?.attributes || []).find(
  //       (attr) =>
  //         attr.attribute_values_id.attribute_id.internal_name ===
  //         "base_selling_price"
  //     ) || {};

  //   const sellingPriceAttr =
  //     _sellingPriceAttrObj?.attribute_values_id?.attribute_id?.id || "";
  //   setSellingPriceAttr(sellingPriceAttr);
  // })

  const onClickCard = useCallback(
    (product, isShortlist) => {
      const price = getPrice(product?.attributes);
      const currentProductData = product;
      const _sellingPriceAttrObj =
        (currentProductData?.attributes || []).find(
          (attr) =>
            attr.attribute_values_id.attribute_id.internal_name ===
            "base_selling_price"
        ) || {};
      const sellingPriceAttr =
        _sellingPriceAttrObj?.attribute_values_id?.attribute_id?.id || "";
      setSellingPriceAttr(sellingPriceAttr);
      if (isShortlist) {
        actions.addProduct(
          product,
          product.id,
          product.variant_meta,
          price,
          sellingPriceAttr
        );
        sendAnalyticsEvent("shortlist_product", { product_id: product?.id });
      } else {
        actions.removeProduct(product, product.id);
        sendAnalyticsEvent("remove_from_shortlist", {
          product_id: product?.id,
        });
      }
    },
    [actions]
  );

  const handleModal = () => {
    setShowModal((state) => !state);
  };

  const handleEducationModal = () => {
    setEducation((state) => !state);
    setItem("visited", true);
  };

  const handleShareModal = () => {
    setShowShareModal((state) => !state);
  };

  const [allowedPPTDownload, setAllowedPPTDownload] = useState(false);
  const [isOptionalEmail, setIsOptionalEmail] = useState(true);
  const [isPasswordReq, setIsPasswordReq] = useState(false);
  const [isLinkExpired, setIsLinkExpired] = useState(false);
  const [isActiveCatalogue, setIsActiveCatalogue] = useState(true);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [setShowFilter, setShowFilterCard] = useState(false);
  const [showExpireModal, setShowExpireModal] = useState(false);
  const [isAuthslug, setIsAuthslug] = useState(false);
  const [info, setInfo] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [newCatalogData, setNewCatalogData] = useState({});
  const [emailOtpUniqueHash, setEmailOtpUniqueHash] = useState("");

  const location = useLocation();
  const strSplit = location.pathname.split("/");

  useEffect(() => {
    if (params?.id) {
      setGlobalState.settings.setCollection_link(params?.id);
    }
  }, [setGlobalState, params?.id]);

  // get collection data using collection link and set it to state and local storage

  const getCatalogueFeatureSettings = async () => {
    try {
      const response = await getManufacturerSettings({
        collection_link: params?.id,
        key: CATALOGUE_SETTINGS_KEY,
      });
      const settings = response?.data?.data || {};
      setGlobalState.settings.setCatalogueSettings(settings);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_SERVICE}link_security/get_verification_data?collection_link=${params.id}`
      )
      .then((res) => {
        console.log("Email-data: ", res?.data?.email);
        setNewCatalogData(res?.data);
        setIsMobile(res?.data?.mobile);
        setIsPasswordReq(res?.data?.passcode);
        setIsOptionalEmail(!res?.data?.email);
        setIsLinkExpired(res?.data?.expiry);
        setAllowedPPTDownload(res?.data?.download_ppt);
        setShowFilterCard(res?.data?.show_products_filter);
        setCollectionStatus(res?.data?.status);
        setIsActiveCatalogue(
          res?.data?.status === "inactive" || res?.data?.status === "archived"
            ? false
            : true
        );

        if (
          res?.data?.status === "inactive" ||
          res?.data?.status === "archived" ||
          res?.data?.expiry
        ) {
          setShowEmailModal(false);
          setShowExpireModal(true);
        } else {
          setShowExpireModal(false);
          setShowEmailModal(true);
        }

        setItem(
          "link_Security_data",
          res?.data ? JSON.stringify(res?.data) : ""
        );
      });
    getCatalogueFeatureSettings();
  }, [collection_link]);

  const handleViewCatalogue = () => {
    history.push({
      pathname: params?.expname
        ? `/${params?.expname}/${params?.id}`
        : `/${params?.id}`,
      search: urlParams.toString(),
    });
    if (newCatalogData?.mobile_otp || newCatalogData?.email_otp) {
      setIsAccessGranted((state) => false);
      setShowEmailModal((state) => true);
    } else {
      setIsAccessGranted((state) => true);
      setShowEmailModal((state) => !state);
    }
    API_KEY.URL.post(
      `custom/email-service/trace/catalogue_opened/${collectionDetails?.id}`,
      {
        time: moment().format("MMMM Do YYYY, h:mm:ss a"),
        buyer_email: getItem("BuyerEmail"),
        city: visitorInfo?.ipLocation?.city?.name,
        country: visitorInfo?.ipLocation?.country?.name,
      }
    );

    removeItem("shortlisted_products");
  };

  const handleQuery = () => {
    sendAnalyticsEvent("buyer_side_view_shortlist", {
      name: "buyer_side_view_shortlist",
      modules: "view Shortlist ",
      location: location.pathname,
      // property: 'create quotation',
    });
    if (urlParams.toString() === "type=PREVIEW") {
      notification.info({
        message:
          "You cannot perform this action because you are browsing in preview mode",
        icon: null,
      });
    } else {
      history.push({
        pathname: params?.expname
          ? `/send-query/${params?.expname}/${params?.id}/${collectionDetails?.exporter_name}`
          : `/send-query/${params?.id}/${collectionDetails?.exporter_name}`,
        search: urlParams.toString(),
      });
    }
    sendAnalyticsEvent("send_query_button", {
      collection_details: collectionDetails?.exporter_name,
    });
  };
  console.log("isAccessGranted: ", isAccessGranted);

  const downloadPPTMenu = (
    <Menu className="download-menu">
      <Menu.Item
        key="0"
        onClick={() => shortlistedPPT()}
        disabled={checkedProducts.length > 0 ? false : true}
        style={{ padding: "10px 20px" }}
      >
        {checkedProducts.length > 0 ? (
          <Row justify="space-between" align="middle">
            <Col>
              <Row>
                <span className="download-title">Download Shortlisted</span>
              </Row>
              <Row>
                <span type="secondary" className="download-sub-title">
                  Download PPT for the shortlisted products
                </span>
              </Row>
            </Col>
            <Col>
              <img
                src={ShortlistSvg}
                alt="Approve"
                // height={"60px"}
                // width={"60px"}
                className="download-ppt-icons"
              />
            </Col>
          </Row>
        ) : (
          <Tooltip title="Please shorlist products to download">
            <Row justify="space-between" align="middle">
              <Col>
                <Row>
                  <span className="download-title">Download Shortlisted</span>
                </Row>
                <Row>
                  <span type="secondary" className="download-sub-title">
                    Download PPT for the shortlisted products
                  </span>
                </Row>
              </Col>
              <Col>
                <img
                  src={ShortlistSvg}
                  alt="Approve"
                  // height={"60px"}
                  // width={"60px"}
                  className="download-ppt-icons"
                />
              </Col>
            </Row>
          </Tooltip>
        )}
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key="1"
        onClick={() => downloadPPT()}
        style={{ padding: "10px 20px" }}
      >
        <Row justify="space-between" align="middle">
          <Col>
            <Row>
              <span className="download-title">Download Entire Catalogue</span>
            </Row>
            <Row>
              <span type="secondary" className="download-sub-title">
                Download PPT for all the products
              </span>
            </Row>
          </Col>
          <Col>
            <img
              src={CatalogueSvg}
              alt="catlougue"
              // height={"60px"}
              // width={"60px"}
              className="download-ppt-icons"
            />
          </Col>
        </Row>
      </Menu.Item>
    </Menu>
  );

  const downloadPPT = () => {
    sendAnalyticsEvent("buyer_side_dw_ppt", {
      name: "buyer_side_dw_ppt",
      modules: "buyer side download",
      location: location.pathname,
    });
    setspinning(true);
    setFiletype("catalogue");
    Axios.get(
      `${process.env.REACT_APP_API_SERVICE}pptservice/?collection_link=${collection_link}`
    )
      .then((res) => {
        console.log(res.data["ppt_url"]);
        let url = res.data["ppt_url"];
        window.open(url, "_self");
        // fileDownload(res.data, `${collectionDetails?.collection_name}.pptx`);
        setspinning(false);
        setFiletype("catalogue");
        sendAnalyticsEvent("download_ppt", {
          collection_link: collection_link,
          collection_name: collectionDetails?.collection_name,
        });
      })
      .catch((err) => {
        setspinning(false);
        setFiletype(null);
      });
  };

  const shortlistedPPT = () => {
    sendAnalyticsEvent("buyer_side_dw_sl_ppt", {
      name: "buyer_side_dw_sl_ppt",
      modules: "buyer side download",
      location: location.pathname,
    });
    var products_list = [];
    checkedProducts.forEach((product) => {
      products_list.push(product?.id);
    });

    setspinning(true);
    setFiletype("shortlisted products");
    Axios.get(
      `${process.env.REACT_APP_API_SERVICE}pptservice/?collection_link=${collectionDetails?.collection_link}&short_listed=${products_list}`
    )
      .then((res) => {
        sendAnalyticsEvent("download_shortlisted_ppt", {
          products_list: products_list,
        });
        console.log(res.data["ppt_url"]);
        let url = res.data["ppt_url"];
        window.open(url, "_self");
        setspinning(false);
        setFiletype("shortlisted products");
      })
      .catch((err) => {
        setspinning(false);
        setFiletype(null);
      });
  };

  const OnTextSearch = (searchText) => {
    setLoader(true);
    setGlobalState.filters.onChangeFilters({
      ...globalState.filters,
      search_keyword: searchText,
    });
    setPage(1);
    axios
      .post(
        `${process.env.REACT_APP_API_SERVICE}items/v2/collection_products/${params.id}`,
        {
          page,
          ...globalState.filters,
          search_keyword: searchText,
          records_per_page: globalState.pageSize,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setHits(res.data?.data?.hits?.length ? res.data.data.hits : []);
          setFacets(
            res.data?.data?.facets && Object.keys(res.data.data.facets).length
              ? formatFacets(res.data.data.facets, globalState.filters)
              : {}
          );
          setProductCount(res.data?.data?.nbHits ? res.data.data.nbHits : 0);
          setNbPage(res?.data?.data?.nbPages);
          setHasMore(res?.data?.data?.nbPages > 1 ? true : false);
        }
        setLoader(false);
      });
  };

  const showAllFilters = () => {
    setShowDrawer(true);
  };

  const addLabelValueBucket = (buckets) => {
    return buckets.map((bucket) => {
      return {
        ...bucket,
        value: bucket.key,
        label: `${bucket.key} (${bucket.doc_count})`,
      };
    });
  };

  const formatFacets = (_facets, _filters) => {
    let _facetsFiltered = {};
    Object.keys(_facets).forEach((_facet) => {
      if (_facets[_facet].buckets.length) {
        _facetsFiltered[_facet] = _facets[_facet];
        _facetsFiltered[_facet].buckets = addLabelValueBucket(
          _facetsFiltered[_facet].buckets
        );
      }
    });
    if (_filters) {
      Object.keys(_filters).forEach((_key) => {
        if (facets[_key] && _facetsFiltered[_key]?.buckets?.length > 0) {
          _facetsFiltered[_key] = { ...facets[_key] };
          _filters[_key].forEach((_bucket) => {
            let index = _facetsFiltered[_key].buckets.findIndex(
              (_b) => _b.key === _bucket
            );
            if (index >= 0) {
              _facetsFiltered[_key].buckets[index].checked = true;
            }
          });
        }
      });
    }

    return _facetsFiltered;
  };

  const hideDrawer = () => {
    setShowDrawer(false);
  };

  const updateQuickFilter = (index) => {
    sendAnalyticsEvent("buyer_side_quick_filter", {
      name: "buyer_side_quick_filter",
      modules: "mobile filter",
      location: location.pathname,
    });
    const _quickFilters = [...globalState.quickFilters];

    _quickFilters[index].selected = _quickFilters[index].selected
      ? false
      : true;

    setLoader(true);
    let _filters = { ...globalState.filters };

    if (_quickFilters[index].selected) {
      // add to filters
      if (_filters[_quickFilters[index].facet]?.length > 0) {
        // _filters[_quickFilters[index].facet].add()
        _filters[_quickFilters[index].facet].push(_quickFilters[index].key);
        // console.log("add filter")
      } else {
        _filters[_quickFilters[index].facet] = [_quickFilters[index].key];
      }
    } else {
      if (_filters[_quickFilters[index].facet]?.length === 1) {
        delete _filters[_quickFilters[index].facet];
      } else {
        let _index = _filters[_quickFilters[index].facet].indexOf(
          _quickFilters[index].key
        );
        _filters[_quickFilters[index].facet].splice(_index, 1);
      }
    }

    setGlobalState.quickFilters.onChangeQuickFilters(_quickFilters);
    setGlobalState.filters.onChangeFilters(_filters);
    setPage(1);
    axios
      .post(
        `${process.env.REACT_APP_API_SERVICE}items/v2/collection_products/${params.id}`,
        { page: 1, ..._filters, records_per_page: globalState.pageSize }
      )
      .then((res) => {
        if (res.status === 200) {
          setHits(res.data?.data?.hits?.length ? res.data.data.hits : []);
          setFacets(
            res.data?.data?.facets && Object.keys(res.data.data.facets).length
              ? formatFacets(res.data.data.facets, _filters)
              : {}
          );
          setProductCount(res.data?.data?.nbHits ? res.data.data.nbHits : 0);
          setNbPage(res?.data?.data?.nbPages);
          setHasMore(res?.data?.data?.nbPages > 1 ? true : false);
        }
        setLoader(false);
      });
  };

  const setQuickFiltersSelected = (_filters) => {
    let _quickFilters = [...globalState.quickFilters];

    _quickFilters = _quickFilters.map((_qf) => {
      if (
        _filters &&
        Object.keys(_filters).length > 0 &&
        _filters[_qf.facet]?.indexOf(_qf.key) >= 0
      ) {
        _qf.selected = true;
      } else {
        _qf.selected = false;
      }
      return _qf;
    });
    setGlobalState.quickFilters.onChangeQuickFilters([..._quickFilters]);
  };

  const applyFilters = (_filters) => {
    setLoader(true);
    setShowDrawer(false);
    setGlobalState.filters.onChangeFilters({ ..._filters });
    setPage(1);

    setQuickFiltersSelected(_filters);
    axios
      .post(
        `${process.env.REACT_APP_API_SERVICE}items/v2/collection_products/${params.id}`,
        { page, ..._filters, records_per_page: globalState.pageSize }
      )
      .then((res) => {
        if (res.status === 200) {
          setHits(res.data?.data?.hits?.length ? res.data.data.hits : []);
          setFacets(
            res.data?.data?.facets && Object.keys(res.data.data.facets).length
              ? formatFacets(res.data.data.facets, _filters)
              : {}
          );
          setProductCount(res.data?.data?.nbHits ? res.data.data.nbHits : 0);
          setNbPage(res?.data?.data?.nbPages);
          setHasMore(res?.data?.data?.nbPages > 1 ? true : false);
        }
        setLoader(false);
      });
  };

  const selectFilter = (selectedValues, _facet) => {
    setLoader(true);
    let _filters = { ...globalState.filters };
    setShowDrawer(false);

    if (!selectedValues && !_facet) {
      const _keys = Object.keys(_filters);
      _keys.forEach((key) => {
        if (key !== "search_keyword") {
          delete _filters[key];
        }
      });
      const _quickFilters = globalState.quickFilters.map((qf) => {
        return { ...qf, selected: false };
      });
      setGlobalState.quickFilters.onChangeQuickFilters(_quickFilters);
    } else {
      if (selectedValues.length === 0) {
        delete _filters[_facet];
      } else {
        _filters[_facet] = selectedValues;
      }
    }

    setGlobalState.filters.onChangeFilters({ ..._filters });
    setPage(1);

    axios
      .post(
        `${process.env.REACT_APP_API_SERVICE}items/v2/collection_products/${params.id}`,
        { page, ..._filters, records_per_page: globalState.pageSize }
      )
      .then((res) => {
        if (res.status === 200) {
          setHits(res.data?.data?.hits?.length ? res.data.data.hits : []);
          setFacets(
            res.data?.data?.facets && Object.keys(res.data.data.facets).length
              ? formatFacets(res.data.data.facets, _filters)
              : {}
          );
          setProductCount(res.data?.data?.nbHits ? res.data.data.nbHits : 0);
          setNbPage(res?.data?.data?.nbPages);
          setHasMore(res?.data?.data?.nbPages > 1 ? true : false);
        }
        setLoader(false);
      });
  };

  const getNextLoad = () => {
    if (nbPages > page) {
      setIsLoadingMore(true);
      let payload = {
        page: page + 1,
        records_per_page: globalState.pageSize,
      };
      if (Object.keys(globalState.filters).length) {
        payload = { ...payload, ...globalState.filters };
      }
      axios
        .post(
          `${process.env.REACT_APP_API_SERVICE}items/v2/collection_products/${params.id}`,
          { ...payload }
        )
        .then((res) => {
          if (res.status === 200) {
            setHits(
              res.data?.data?.hits?.length
                ? [...hits, ...res.data.data.hits]
                : [...hits]
            );
            // setFacets(res.data?.data?.facets && Object.keys(res.data.data.facets).length  ? formatFacets(res.data.data.facets, _filters) : {});
            setProductCount(res.data?.data?.nbHits ? res.data.data.nbHits : 0);
            setNbPage(res?.data?.data?.nbPages);
            setHasMore(res?.data?.data?.nbPages > 1 ? true : false);
            setPage((_page) => _page + 1);
          }
          setIsLoadingMore(false);
        });
    }
  };

  const resetFilter = () => {
    setLoader(true);
    setGlobalState.filters.onChangeFilters({}); // Empty json means no filters
    setQuickFiltersSelected({}); // Empty json means no filters, reset to first load state
    setPage(1);
    axios
      .post(
        `${process.env.REACT_APP_API_SERVICE}items/v2/collection_products/${params.id}`,
        { page: 1, records_per_page: globalState.pageSize }
      )
      .then((res) => {
        if (res.status === 200) {
          setHits(
            res.data?.data?.hits?.length
              ? [...hits, ...res.data.data.hits]
              : [...hits]
          );
          setFacets(
            res.data?.data?.facets && Object.keys(res.data.data.facets).length
              ? formatFacets(res.data.data.facets)
              : {}
          );
          setProductCount(res.data?.data?.nbHits ? res.data.data.nbHits : 0);
          setNbPage(res?.data?.data?.nbPages);
          setHasMore(res?.data?.data?.nbPages > 1 ? true : false);
        }
        setLoader(false);
      });
  };

  const renderDetails = () => {
    const handleSliderView = () => {
      history.push({
        pathname: params?.expname
          ? `/slider-view/${params?.expname}/${collection_link}`
          : `/slider-view/${collection_link}`,
        search: urlParams.toString(),
      });
      sendAnalyticsEvent("show_product_details", {
        collection_link: collection_link,
      });
    };
    console.log(collectionDetails?.products?.length);
    return (
      <div>
        <StickyBox
          //offsetTop={64}
          // offsetBottom={20}
          className={`stickyHeader z-[80] ${
            deviceType === "mobile" ? "noPadding" : ""
          }`}
          style={{
            background: Colors.white,
            height: "5.5em",
            zIndex: 999,
            //paddingBottom: 10,

            borderBlockEnd: "1px solid #E6E7E7",

            borderRadius: "0.2em",
          }}
        >
          <Row align={"middle"}>
            <Col
              xs={{ span: 20, offset: 0 }}
              sm={{ span: 20, offset: 0 }}
              md={{ span: 12, offset: 0 }}
              xl={{ span: 12, offset: 0 }}
              lg={{ span: 12, offset: 0 }}
            >
              <Row align="middle">
                <Col
                // style={{width:"80px", height: "80px"}}
                >
                  <Image
                    preview={false}
                    height="100%"
                    width="100%"
                    // width={100}
                    className="buyer-logo"
                    src={
                      collectionDetails?.logo
                        ? `${process.env.REACT_APP_DIRECTUS}assets/${collectionDetails?.logo}?quality=100`
                        : ImagePlacholder
                    }
                    alt="Buyer Portal"
                    style={{
                      display: "block",
                      borderRadius: "5px",
                      objectFit: "contain",
                      margin: "0.5rem 0",
                      width: `${deviceType === "mobile" ? "40px" : "65px"}`,
                      height: `${deviceType === "mobile" ? "40px" : "65px"}`,
                    }}
                  />
                </Col>
                <Col
                  md={{ span: 18, offset: 0 }}
                  xs={{ span: 16, offset: 0 }}
                  style={{ marginLeft: "0.5em" }}
                >
                  <Row>
                    <Text
                      className="exporterName"
                      level={5}
                      style={{ fontFamily: "Gilroy", margin: 0 }}
                    >
                      {collectionDetails?.exporter_name ||
                        collectionDetails?.buyer_name}
                    </Text>
                  </Row>
                  {/* <Row style={{ paddingBottom: "0.4em" }}>
                    <Text style={{ color: Colors.gray }}>
                      {collectionDetails?.collection_name}
                    </Text>
                  </Row> */}
                </Col>
              </Row>
            </Col>
            <Col
              xs={{ span: 4, offset: 0 }}
              sm={{ span: 4, offset: 0 }}
              md={{ span: 12, offset: 0 }}
              lg={{ span: 12, offset: 0 }}
              xl={{ span: 12, offset: 0 }}
            >
              <Row
                className="pb-2 catlogue-actions"
                justify="end"
                align="bottom"
                style={{ display: "flex", justifyContent: "end" }}
              >
                <Space align="baseline">
                  <span
                    disabled={!checkedProducts.length}
                    className={`flex`}
                    onClick={() => handleQuery()}
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      className="img wishlistIcon"
                      src={
                        checkedProducts.length > 0
                          ? ShortlistBlip
                          : ShortlistOutline
                      }
                      alt="wishlist"
                      width={deviceType === "mobile" ? 24 : 18}
                      height={deviceType === "mobile" ? 24 : 18}
                    />
                    {/* <MediaQuery minWidth={600}> */}
                    {deviceType !== "mobile" && (
                      <span>
                        {checkedProducts.length
                          ? ` My Shortlist (${checkedProducts.length})`
                          : ` My Shortlist`}
                      </span>
                    )}
                    {/* </MediaQuery> */}
                    {/* <MediaQuery maxWidth={500}> */}
                    {deviceType === "mobile" && (
                      <span style={{ fontSize: "14px" }}>
                        {checkedProducts.length
                          ? `  (${checkedProducts.length})`
                          : ` `}
                      </span>
                    )}
                    {/* </MediaQuery> */}
                  </span>
                </Space>
              </Row>
            </Col>
          </Row>
        </StickyBox>

        <div
          className={!isAccessGranted ? "bg-image" : ""}
          style={{ zIndex: 0 }}
        >
          <ExpireLink
            collectionStatus={collectionStatus}
            showModal={showExpireModal}
            collectionDetails={collectionDetails}
          />

          <OtpVerficationModal
            setOtpModal={setOtpModal}
            isOtpModal={isOtpModal}
            otpEmail={otpEmail}
            setOtpEmail={setOtpEmail}
            otpMobile={otpMobile}
            setOtpMobile={setOtpMobile}
            setShowEmailModal={setShowEmailModal}
            countryCode={countryCode}
            newCatalogData={newCatalogData}
            setEmailOtpUniqueHash={setEmailOtpUniqueHash}
            emailOtpUniqueHash={emailOtpUniqueHash}
            setIsAccessGranted={setIsAccessGranted}
            setIsUserAllowed={setIsUserAllowed}
            sessionId={sessionId}
            collection_link={collection_link}
            isOtpModalWatermark={isOtpModalWatermark}
            handleCatalogue={handleViewCatalogue}
          />

          <NoAccessModal
            setIsNoAccessModal={setIsNoAccessModal}
            isNoAccessModal={isNoAccessModal}
            setShowEmailModal={setShowEmailModal}
            showModal={showEmailModal && !isAccessGranted}
            newCatalogData={newCatalogData}
          />

          <EmailInputModal
            isOptionalEmail={isOptionalEmail}
            showModal={showEmailModal && !isAccessGranted}
            setShowModal={setShowEmailModal}
            setIsAccessGranted={setIsAccessGranted}
            handleCatalogue={handleViewCatalogue}
            isPasswordReq={isPasswordReq}
            isLinkExpired={isLinkExpired}
            isActiveCatalogue={isActiveCatalogue}
            collectionDetails={collectionDetails}
            collection_link={params?.id}
            allowedPPTDownload={allowedPPTDownload}
            showFilters={setShowFilter}
            imageQuality={imageQuality}
            fetchingUserData={fetchingUserData}
            setOtpModal={setOtpModal}
            isOtpModal={isOtpModal}
            otpEmail={otpEmail}
            setOtpEmail={setOtpEmail}
            otpMobile={otpMobile}
            setOtpMobile={setOtpMobile}
            setIsNoAccessModal={setIsNoAccessModal}
            isNoAccessModal={isNoAccessModal}
            setCountryCode={setCountryCode}
            countryCode={countryCode}
            handleChange={handleChange}
            newCatalogData={newCatalogData}
            setEmailOtpUniqueHash={setEmailOtpUniqueHash}
            emailOtpUniqueHash={emailOtpUniqueHash}
            setIsUserAllowed={setIsUserAllowed}
            sessionId={sessionId}
            isUserAllowed={isUserAllowed}
            isEmailModalWatermark={isEmailModalWatermark}
          />

          <Loading visible={spinning} fileType={filetype} />
          <EducationModal
            showModal={education}
            handleModal={handleEducationModal}
          />
          <ProductPreviewModal
            modalData={modalData}
            currentProductIndex={currentProductIndex}
            showModal={showModal}
            nextProduct={nextProduct}
            prevProduct={prevProduct}
            allProductHits={allProductHits}
            handleModal={handleModal}
            handleNext={handleNext}
            handlePrev={handlePrev}
            imgLoaded={imgLoaded}
            setImgLoaded={setImgLoaded}
            handleShortlist={onClickCard}
            isShortlisted={checkedProducts.some((p) => p.id === modalData.id)}
            collectionDetails={collectionDetails}
          />

          <Modal
            centered
            footer={null}
            visible={showShareModal}
            onCancel={handleShareModal}
          >
            <ShareCatalogue />
          </Modal>

          <div className="maindiv">
            {isPreview && (
              <Alert
                showIcon={false}
                message={
                  <span>
                    <WarningFilled style={{ color: "red" }} /> This is a preview
                    of the catalogue. Do not share this link with the buyer.
                  </span>
                }
                style={{
                  color: Colors.white,
                  fontSize: "1.5rem",
                  textAlign: "center",
                  background: "#FDDBDB",
                  //top: 64,
                  zIndex: 0,
                }}
                banner
              />
            )}
            <Row className="firstRow">
              <Col
                md={deviceType !== "mobile" ? 12 : 20}
                // xs={16}
                xs={window.innerWidth < 347 ? 14 : 16}
                sm={window.innerWidth < 470 ? 16 : 18}
              >
                {!hide_catalogue_name && <Text className="collectionName">
                  {collectionDetails?.collection_name}
                </Text>}
                <div className="productsCount">
                  {productCount} {productCount > 1 ? " Products" : " Product"}
                </div>
              </Col>
              <Col
                md={deviceType !== "mobile" ? 12 : 4}
                xs={window.innerWidth < 347 ? 10 : 8}
                sm={window.innerWidth < 470 ? 8 : 6}
                className="download"
              >
                {/* <MediaQuery maxWidth={600}> */}
                {deviceType === "mobile" && window.innerWidth < 768 && (
                  <Row style={{ display: "flex", justifyContent: "end" }}>
                    <Col
                      className={`gridView double ${
                        mobileCols === 2 ? "selected" : ""
                      }`}
                    >
                      <span
                        onClick={() => {
                          setMobileCols(2);
                          localStorage.setItem("mobileCols", 2);
                        }}
                      >
                        <img src={DualCol} alt="Two column" />
                      </span>
                    </Col>
                    <Col
                      className={`gridView single ${
                        mobileCols === 1 ? "selected" : ""
                      }`}
                    >
                      <span
                        onClick={() => {
                          setMobileCols(1);
                          localStorage.setItem("mobileCols", 1);
                        }}
                      >
                        <img src={SingleCol} alt="Two column" />
                      </span>
                    </Col>
                  </Row>
                )}
                {/* </MediaQuery> */}
                {collectionDetails?.products?.length < maxAllowedProducts &&
                  deviceType !== "mobile" &&
                  allowedPPTDownload && (
                    <Row justify="end">
                      <Dropdown trigger="click" overlay={downloadPPTMenu}>
                        <Button
                          // className="ant-dropdown-link w-max flex downloadBtn"
                          // height="48"
                          onClick={() => {
                            sendAnalyticsEvent(
                              "buyer_side_download_click_ppt",
                              {
                                name: "buyer_side_download_click_ppt",
                                modules: "Download PPT",
                                location: location.pathname,
                              }
                            );
                          }}
                          size="large"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                          {/* <div className="flex h-full space-x-2 items-center"> */}
                          <MdCloudDownload
                            style={{ marginRight: "5px" }}
                            className="w-4"
                            size={16}
                          />
                          <Text style={{ fontWeight: 600 }}>Download PPT</Text>
                          {/* </div> */}
                        </Button>
                      </Dropdown>
                    </Row>
                  )}
              </Col>
            </Row>
            <Row style={{ position: "relative", paddingLeft: "10px" }}>
              {showBannerOnTop && deviceType !== "mobile" && (
                <>
                  <span
                    className="closeIconBanner"
                    onClick={() => setShowBannerOnTop(false)}
                  >
                    <img src={CloseIcon} alt="Close"></img>
                  </span>
                  <HeaderImageComponent />
                </>
              )}
            </Row>

            <Row>
              <Col span={24} style={{ backgroundColor: "white" }}>
                <CatalogueDetails
                  productCount={productCount}
                  OnTextSearch={OnTextSearch}
                  showFilter={setShowFilter}
                  Facets={facets}
                  selectFilter={selectFilter}
                  Filters={globalState.filters}
                  showAllFilters={showAllFilters}
                  hits={hits}
                  loader={loader}
                  collection_link={collection_link}
                  handleCardClick={handleCardClick}
                  onClickCard={onClickCard}
                  checkedProducts={checkedProducts}
                  handleNext={handleNext}
                  handlePrev={handlePrev}
                  imageQuality={imageQuality}
                  mobileCols={mobileCols}
                  refineNext={getNextLoad}
                  hasMore={hasMore}
                  resetFilters={resetFilter}
                  // quickFilters={quickFilters}
                  updateQuickFilter={updateQuickFilter}
                  id={id}
                  isLoadingMore={isLoadingMore}
                  isCatalogueWatermark={isCatalogueWatermark}
                  extraAttributeToRender={extraAttributeToRender}
                />
              </Col>
            </Row>
          </div>
        </div>
        {showDrawer && (
          <CatalogueDrawer
            closeAllFilters={() => {
              setShowDrawer(false);
            }}
            Facets={facets}
            Filters={globalState.filters}
            hideDrawer={hideDrawer}
            applyFilters={applyFilters}
            clearAll={selectFilter}
          />
        )}
      </div>
    );
  };
  return info?.manufacturer_slug ? (
    isAuthslug ? (
      renderDetails()
    ) : (
      <></>
    )
  ) : params?.expname ? (
    <></>
  ) : (
    renderDetails()
  );
}

const mapStateToProps = (state) => ({
  checkedProducts: state.checkedProducts.data,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      addProduct: ProductActions.addProduct,
      removeProduct: ProductActions.removeProduct,
      setProducts: ProductActions.setProducts,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Catalogue);
